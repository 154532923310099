// DirtyChecker.prototype.dirtyAttributes
export default function() {
  var dirty = {};

  for (var _i = 0, _a = Object.keys(this.model.attributes); _i < _a.length; _i++) {
    var key = _a[_i];
    var prior = this.model._originalAttributes[key];
    var current = this.model.attributes[key];

    if (!this.model.isPersisted) {
      dirty[key] = [null, current];
    } else if (prior !== current) {
      // array and vue-observer-decorated array should be treated as equal
      // prior == []
      // current = [__ob__: Observer]
      if (JSON.stringify(prior) !== JSON.stringify(current)) {
        dirty[key] = [prior, current];
      }
    }
  }

  return dirty;
};

