import h from './helpers'

export default {
  blank: 'no puede estar en blanco',
  select_all: 'Seleccionar Todas',
  datepicker: {
    ok: 'Ok',
    cancel: 'Cancelar'
  },
  adjuntos: {
    label: (count) => `${count} adjunto/s`,
  },
  reportes: {
    exportar: 'Exportar a Excel'
  },
  alumnos: {
    fields: {
      asignaturas: {
        caption: 'Asignaturas'
      },
      justificacion: {
        caption: 'Justificación'
      },
      llegadaTarde: {
        caption: 'Llegada tarde'
      },
      enOtraBurbuja: {
        caption: 'Este alumno NO pertenece a esta burbuja'
      },
      notificable: {
        caption: 'Notificar esta ausencia'
      },
      observaciones: {
        caption: 'Observaciones'
      },
      otra_justificacion: {
        caption: 'Otra Justificación'
      },
      presentismo: {
        caption: 'Presentismo'
      },
    },
  },
  asistencias: {
    counters: {
      presentes: (stat = {}) =>
        `Alumnos presentes: ${h.presence(stat.presentes, '-')} de ${h.presence(stat.total, '-')}`,
      total: (stat = {}) => `${stat.total || ''} alumnos`,
    },
    fields: {
      observaciones: {
        caption: 'Observaciones',
        hint: 'observaciones del curso en el día de la fecha',
      },
    },
    cerrar: 'Cerrar',
    guardar: 'Guardar Cambios',
    pasar_lista: 'Pasar Lista',
    marcar_presentes: 'Marcar a todos como PRESENTES',
    marcar_ausentes: 'Marcar a todos como AUSENTES',
  },
}
