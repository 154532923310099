<template lang='pug'>
  .asistencias-list
    asistencia-card(
      v-for='asistencia of asistencias'
      :key='`asistencia#${asistencia.id}`'
      :asistencia='asistencia'
    )
</template>

<script>
  import { Asistencia } from '../../../models/asistencia.model'

  export default {
    props: {
      fecha: { type: String, required: true },
      unidadEducativaId: { type: String, required: true },
    },
    data: () => {
      return {
        asistencias: [],
        error: null,
      }
    },
    mounted() {
      this.fetch()
    },
    computed: {
      fechaUnidadEducativaId() {
        return [this.fecha, this.unidadEducativaId]
      }
    },
    watch: {
      fechaUnidadEducativaId(_nuevo, _viejo) {
        this.fetch()
      },
    },
    methods: {
      fetch: _.debounce(function () {
        this.$emit('load-start')
        this.onError(null)

        Asistencia.para(this.unidadEducativaId, this.fecha).all()
          .then(res => {
            this.asistencias = res.data.filter(asis => asis.stat.total > 0)
          })
          .catch(err => this.onError(err))
          .finally(() => this.$emit('load-stop'))
      }, 1000),
      closeAllBut: function(target) {
        for (var idx = 0; idx < this.$children.length; idx++) {
          let child = this.$children[idx]
          if (child !== target) {
            child.toggle(false)
          }
        }
      },
      onError(err) {
        window.last_error = err

        if (err != null && typeof err !== 'undefined') {
          this.$emit('error', err)
        }
      }
    },
  }
</script>

<style lang='scss'>
</style>
