import { ApplicationRecord } from './application_record'
import { AsistenciaAlumno } from './asistencia_alumno.model'
import { attr, hasMany } from 'spraypaint';

export const Asistencia = ApplicationRecord.extend({
  static: {
    jsonapiType: 'asistencias',

    collection() {
      return this
        .selectExtra(['nombre_curso', 'stat'])
        .order('ordenadas')
    },
    para(ueid, fecha) {
      return this
        .collection()
        .where({ unidad_educativa_id_and_fecha: [ueid, fecha] })
    },
    conTodo() { // FIXME: no puedo componer scopes estáticos
      return this
        .includes('asistencias_alumno')
        .selectExtra(['presentismos_posibles', 'justificaciones_posibles', 'asignaturas_posibles'])
        .merge({ asistencias_alumno: AsistenciaAlumno.ordenadas() })
    }
  },
  attrs: {
    observaciones: attr(),

    asignaturasPosibles: attr({ persist: false }),
    fecha: attr({ persist: false }),
    id: attr({ persist: false }),
    justificacionesPosibles: attr({ persist: false }),
    nombreCurso: attr({ persist: false }),
    presentismosPosibles: attr({ persist: false }),
    registraLlegadasTarde: attr({ persist: false }),
    stat: attr({ persist: false }),

    asistenciasAlumno: hasMany(),
  },
  methods: {
    recalcularStats() {
      this.stat.presentes = this.asistenciasAlumno.
        filter((aa) => aa.presentismo !== null && aa.presentismo !== 'ausente').
        length
      this.stat.total = this.asistenciasAlumno.length
    },
    displayName() {
      return `${this.nombreCurso} / ${this.fecha}…`
    },
    fetchDetalles() {
      let asis = this
      return Asistencia
        .conTodo()
        .find(this.id)
        .then(res => {
          asis.asistenciasAlumno = res.data.asistenciasAlumno
          return res
        })
    },
    marcarTodos(presentismo) {
      if (this.asistenciasAlumno.length > 0) {
        this.doMarcarTodos(presentismo)
        return Promise.resolve()
      } else {
        return this.fetchDetalles()
          .then(_res => this.doMarcarTodos(presentismo))
      }
    },
    doMarcarTodos(presentismo) {
      this.asistenciasAlumno.forEach(alumno => {
        alumno.resetPresentismo(presentismo)
      })
    }

  },
})
