<template lang='pug'>
  v-card-actions.px-4.asistencia-action-bar
    v-btn(
      v-if='!isOpen'
      :disabled='isSaving'
      @click='$emit("open")'
      small text
      color='accent'
    )
      .
        {{i18n.asistencias.pasar_lista}}
      v-icon {{icons.expand}}
    v-btn(
      v-if='isOpen'
      :disabled='isSaving'
      @click='$emit("save")'
      small text
      color='white'
    )
      v-icon(v-if='!isSaving') {{icons.disk}}
      v-progress-circular(
        v-if='isSaving'
        fab indeterminate
        size=12
        width=2
      )
      .
        {{i18n.asistencias.guardar}}
    .flex-grow-1
    slot(name='chips')
    slot(name='menu')
</template>

<script>
  import { i18n, icons } from '@/../js/util'

  export default {
    props: {
      isSaving: { type: Boolean, default: false },
      isOpen: { type: Boolean, default: false },
    },
    data: () => {
      return {
        i18n,
        icons,
      }
    },
    computed: {
    },
    methods: {
    },
  }
</script>

<style lang='scss' scoped>
</style>
