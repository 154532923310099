<template lang='pug'>
  .text-center(v-if='loading')
    v-progress-circular(
      indeterminate button
      size=48
      color='secondary'
    )
  .alumnos-list(v-else='loading')
    alumno-cell(
      v-for='(alumno, idx) in alumnos'
      :key='`alumno#${alumno.id}`'
      :alumno='alumno'
      :asignaturasPosibles='asignaturasPosibles'
      :index='idx'
      :justificacionesPosibles='justificacionesPosibles'
      :presentismosPosibles='presentismosPosibles'
      :registraLlegadasTarde='registraLlegadasTarde'
      @change='changed()'
    )
</template>

<script>
  import { Asistencia } from '../../../models/asistencia.model'

  export default {
    props: {
      asistencia: { type: Asistencia, required: true },
    },
    data: () => {
      return {
        alumnos: [],
        asignaturasPosibles: [],
        error: null,
        justificacionesPosibles: [],
        loading: false,
        presentismosPosibles: [],
        registraLlegadasTarde: null,
      }
    },
    mounted() {
      this.fetch()
    },
    methods: {
      changed() {
        this.$emit('change')
      },
      fetch() {
        this.loading = true
        this.$emit('load-start')
        this.onError(null)

        this.asistencia
          .fetchDetalles()
          .then(res => this.storeDetalles(res.data))
          .catch(err => this.onError(err))
          .finally(() => {
            this.loading = false
            this.$emit('load-stop')
          })
      },
      storeDetalles(data) {
        this.alumnos = data.asistenciasAlumno
        this.asignaturasPosibles = data.asignaturasPosibles
        this.justificacionesPosibles = data.justificacionesPosibles
        this.presentismosPosibles = data.presentismosPosibles
        this.registraLlegadasTarde = data.registraLlegadasTarde
      },
      onError(err) {
        this.error = window.last_error = err

        if (err != null && typeof err !== 'undefined') {
          this.$emit('error', err)
        }
      }
    }
  }
</script>

<style lang='scss'>
  .asistencia-card--body {
    margin-top: 32px;

    .v-progress-circular {
      margin-bottom: 32px;
    }
  }
</style>
