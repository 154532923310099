import { ApplicationRecord } from './application_record'
import { attr } from 'spraypaint';

export const Anuncio = ApplicationRecord.extend({
  static: {
    jsonapiType: 'anuncios',
  },
  attrs: {
    id: attr({ persist: false }),
    numero: attr({ persist: false }),
    titulo: attr({ persist: false }),
    cuerpo: attr({ persist: false }),
    fechaPublicacion: attr({ persist: false }),
    prioridad: attr({ persist: false }),
    categoria: attr({ persist: false }),
    tags: attr({ persist: false }),
    adjuntos: attr({ persist: false }),
    leido: attr(),
    favorito: attr()
  },
  methods: {
  },
})

window.view_models || (window.view_models = {})
window.view_models.Anuncio = Anuncio
