import Vue from 'vue'

import AsistenciasIndex from './vue/asistencias_index'
Vue.component('asistencias-index', AsistenciasIndex)

import AsistenciasList from './vue/asistencias_list'
Vue.component('asistencias-list', AsistenciasList)

import AsistenciaCard from './vue/asistencia_card'
Vue.component('asistencia-card', AsistenciaCard)

import AsistenciaActionBar from './vue/asistencia_action_bar'
Vue.component('asistencia-action-bar', AsistenciaActionBar)

import AsistenciaChips from './vue/asistencia_chips'
Vue.component('asistencia-chips', AsistenciaChips)

import AsistenciaMenu from './vue/asistencia_menu'
Vue.component('asistencia-menu', AsistenciaMenu)

import AlumnosList from './vue/alumnos_list'
Vue.component('alumnos-list', AlumnosList)

import AlumnoCell from './vue/alumno_cell'
Vue.component('alumno-cell', AlumnoCell)
