import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from './colors'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    themes: colors.comenius
  }
})
