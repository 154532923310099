<template lang='pug'>
  //- expects either a #title prop or a #title named slot
  //- `v-app` must exist only once in the page, try to move it elsewhere
  v-app
    v-card.comenius-container(:loading='loading')
      v-toolbar(color='primary' dark)
        .container-toolbar--nav-icon(v-if='navIcon')
          slot(name='navIcon')
            v-app-bar-nav-icon/
        v-toolbar-title.container-toolbar--title
          slot(name='title')
            .
              {{computedTitle}}
        .container-toolbar--actions
          slot(name='actions')
      v-content
        v-sheet
          v-container
            slot/
</template>

<script>
  export default {
    props: {
      actions: {
        default() { return [] },
        type: Array,
      },
      loading: Boolean,
      navIcon: Boolean, // [Boolean | String]
      title: {
        required: false,
        type: String,
      },
    },
    data() {
      return {
      }
    },
    computed: {
      computedTitle() {
        return this.$props.title || 'title';
      }
    },
  }
</script>

<style lang='scss'>
  .comenius-container {
    margin: 0 auto;
    max-width: 50em !important;
    touch-action: manipulation;
    width: 100%;

    // @extend .d-flex;
    display: flex;

    // .container-toolbar--nav-icon {
    // }

    .container-toolbar--title {
      // @extend .flex-grow-1;
      flex-grow: 1;
    }

    .container-toolbar--actions {
      margin: 1em;

      // @extend .flex-shrink-0;
      flex-shrink: 0;
    }
  }
</style>
