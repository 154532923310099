import { SpraypaintBase } from 'spraypaint';

// ref https://github.com/graphiti-api/spraypaint.js/pull/39
import { ValidationErrorBuilder as VEB } from "spraypaint/lib-esm/util/validation-error-builder"
import DC from "spraypaint/lib-esm/util/dirty-check"

import monkey_patch from './spraypaint_patch'
VEB.prototype._OLD_processRelationship = VEB.prototype._processRelationship
VEB.prototype._processRelationship = monkey_patch

import isdirty_patch from './spraypaint_isdirty_patch'
DC.prototype.OLD_dirtyAttributes = DC.prototype.dirtyAttributes
DC.prototype.dirtyAttributes = isdirty_patch

export const ApplicationRecord = SpraypaintBase.extend({
  static: {
    apiNamespace: '/api/v1',
    baseUrl: '',
    clientApplication: 'comenius-web',
  },
  methods: {
    mid() {
      return `${this.klass.jsonapiType}#${this.id}`
    },
    matchesMeta(meta) {
      let matchesType = this.klass.jsonapiType === meta.type

      let hasId = !!this.id
      let matchesId = this.id == meta.id

      let hasTempId = !!this.temp_id
      let matchesTempId = this.temp_id == meta['temp-id']

      return matchesType && (hasId && matchesId || hasTempId && matchesTempId)
    },
    anyErrors() {
      return Object.keys(this.errors).length > 0
    },
    hasErrorFor() {
      // return arguments.some(attr => !!this.errors[attr])
      let args = Array.prototype.slice.call(arguments)

      return args.some(attr => !!this.errors[attr])
    },
    errorsFor() {
      let args = Array.prototype.slice.call(arguments)
      let errors = args.map(
        attr => {
          let error = this.errors[attr]
          return error && error.message
        }
      )

      return [].concat(...errors) // errors.flat()
        .filter(msg => !!msg)
    },
  }
})

