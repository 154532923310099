const isNullish = (val) => {
  return val === null || typeof val === 'undefined'
}

const isBlank = (val) => {
  return isNullish(val)
    || (val.hasOwnProperty('length') && val.length === 0)
    || (typeof val.filter === 'function' && val.filter((item) => !isBlank(item)).length === 0)
}

const isPresent = (val) => !isBlank(val);

const presence = (val, fallback) => {
  if (arguments.length < 2) {
    fallback = false
  }

  return isBlank(val) ? fallback : val
}

export default {
  isNullish,
  isBlank,
  isPresent,
  presence
}
