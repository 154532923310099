import {
  mdiAccount,
  mdiAccountOutline,
  mdiAccountSupervisorOutline,
  mdiAlertOctagram,
  mdiCalendarToday,
  mdiChartLine,
  mdiCheckAll,
  mdiCheckboxBlankOutline,
  mdiChevronDoubleDown,
  mdiChevronDoubleUp,
  mdiChevronDown,
  mdiChevronUp,
  mdiClose,
  mdiCloseBox,
  mdiContentSaveOutline,
  mdiDotsVertical,
  mdiEarth,
  mdiEmail,
  mdiEmailOutline,
  mdiExport,
  mdiExportVariant,
  mdiFlaskEmptyOutline,
  mdiInformation,
  mdiInformationOutline,
  mdiMinusBox,
  mdiNoteMultipleOutline,
  mdiPhone,
  mdiPhoneClassic,
  mdiPlusBox,
  mdiStar,
  mdiStarOutline,
  mdiText,
} from '@mdi/js'

export default {
  absence: mdiAccountOutline,
  account_supervisor: mdiAccountSupervisorOutline,
  alert: mdiAlertOctagram,
  blank: 'M0.41,0.41', // :shrug:
  calendar: mdiCalendarToday,
  chart: mdiChartLine,
  check_outline: mdiCheckboxBlankOutline,
  clear: mdiCheckAll,
  close: mdiClose,
  close_box: mdiCloseBox,
  collapse: mdiChevronUp,
  disk: mdiContentSaveOutline,
  earth: mdiEarth,
  export: mdiExport,
  flask: mdiFlaskEmptyOutline,
  email: mdiEmail,
  email_outline: mdiEmailOutline,
  info: mdiInformation,
  info_outline: mdiInformationOutline,
  minus_box: mdiMinusBox,
  note: mdiNoteMultipleOutline,
  phone: mdiPhone,
  phone_classic: mdiPhoneClassic,
  plus_box: mdiPlusBox,
  presence: mdiAccount,
  dots: mdiDotsVertical,
  expand: mdiChevronDown,
  star_off: mdiStarOutline,
  star_on: mdiStar,
  text: mdiText
}
