<template lang='pug'>
  .asistencia-card(:data-key='`asistencia#${asistencia.id}`')
    v-card(:elevation='elevation')
      v-sheet.asistencia-card--header(
        :class="{ 'asistencia--header-open': isOpen }"
        :color='isOpen ? (isError ? "warning" : "secondary") : undefined'
        :dark='isOpen'
      )
        v-card-title.headline.mb-1
          .
            {{asistencia.nombreCurso}}
          .flex-grow-1
          v-btn.pull-right(
            :color='isOpen ? "white" : (isError ? "error" : "accent")'
            fab small text
          )
            v-progress-circular(
              v-if='isSaving'
              fab indeterminate
              size=12
              width=2
            )
            v-icon(v-if='isError') {{icons.alert}}
            v-icon(v-if='isIdle') {{favorite ? icons.blank : icons.blank}}
        v-card-text.subtitle-1.
          {{i18n.asistencias.counters.presentes(asistencia.stat)}}
        v-card-actions.px-4
          v-textarea(
            v-model='asistencia.observaciones'
            :hint='i18n.asistencias.fields.observaciones.hint'
            :label='i18n.asistencias.fields.observaciones.caption'
            @change='updateAsistencia'
            auto-grow clearable hide-details outlined persistent-hint
            rows=1
          )
        asistencia-action-bar(
          :isOpen='isOpen'
          :isSaving='isSaving'
          :isLoading='isLoading'
          @open='open()'
          @save='save()'
        )
          template(v-slot:chips)
            asistencia-chips(
              :isOpen='isOpen'
              :errorsCount='errorsCount'
              :incompletosCount='incompletosCount'
              :isError='isError'
              :isIncompleto='isIncompleto'
            )
          template(v-slot:menu)
            asistencia-menu(
              @toggleAll='marcarTodos($event)'
            )
      .asistencia-card--body.px-4(v-if='isOpen')
        alumnos-list(
          :asistencia='asistencia'
          @change='updateAsistencia'
          @error='onError'
          @load-start='isLoading = true'
          @load-stop='isLoading = false'
        )
      v-sheet.asistencia-card--header(
        v-if='isOpen'
        :class="{ 'asistencia--header-open': isOpen }"
        :color='isOpen ? (isError ? "warning" : "secondary") : undefined'
        :dark='isOpen'
      )
        asistencia-action-bar(
          :isOpen='isOpen'
          :isLoading='isLoading'
          @open='open()'
          @save='save()'
        )
          template(v-slot:chips)
            asistencia-chips(
              :errorsCount='errorsCount'
              :incompletosCount='incompletosCount'
              :isError='isError'
              :isIncompleto='isIncompleto'
              :isOpen='isOpen'
            )
</template>

<script>
  import { h, i18n, icons } from '@/../js/util'
  import { Asistencia } from '@/../models/asistencia.model'

  export default {
    props: {
      asistencia: { type: Asistencia, required: true },
      autoSave: { type: Number, default: 5 }, // período en segundos
    },
    data: () => {
      return {
        favorite: false,
        i18n,
        icons,
        isError: false,
        isLoading: false,
        isOpen: false,
        saving: false,
      }
    },
    computed: {
      elevation() {
        return this.isOpen ? 8 : 2
      },
      isIdle() {
        return !this.isError && !this.saving
      },
      isSaving() {
        return this.saving && !this.isError
      },
      errorsCount() {
        if (!this.isError) {
          return null
        }

        let alumnos = this.asistencia.asistenciasAlumno

        if (h.isNullish(alumnos)) {
          return 0
        }

        return alumnos.filter(alum => alum.anyErrors()).length
      },
      isIncompleto() {
        if (this.isError) {
          return null
        }

        let alumnos = this.asistencia.asistenciasAlumno

        if (h.isNullish(alumnos) || alumnos.length === 0) {
          return null
        }

        let count = this.incompletosCount

        return true;
      },
      incompletosCount() {
        if (this.isError) {
          return null
        }

        let alumnos = this.asistencia.asistenciasAlumno

        if (h.isNullish(alumnos) || alumnos.length === 0) {
          return null
        }

        return alumnos.filter(alum => h.isNullish(alum.presentismo)).length
      },
    },
    mounted() {
      this.asistencia.fetchDetalles()
      this.debouncedSaveAsistencia = _.debounce(this.saveAsistencia, this.autoSave * 1000)
    },
    methods: {
      open() {
        if (!this.isOpen) {
          this.onError(null)

          this.isOpen = true
          this.$emit('opened', this)
        }
      },
      save() {
        if (this.isOpen) {
          this.onError(null)
          this.isSaving = true

          this.debouncedSaveAsistencia.cancel()
          this.saveAsistencia().then(success => {
            if (success) {
              this.isOpen = false
              this.$emit('closed', this)
            }
          }).finally(() => this.isSaving = false)
        }
      },
      updateAsistencia() {
        this.debouncedSaveAsistencia()

        this.asistencia.recalcularStats()
      },
      saveAsistencia() {
        this.saving = true

        // FIXME onError se usa para múltiples errores
        return this.asistencia
          .save({ with: 'asistenciasAlumno' })
          .then(success => {
            if (!success) {
              this.onError(this.asistencia.errors);
            }

            return success
          })
          .catch(err => this.onError(err))
          .finally(_.delay(() => this.saving = false, 500))

      },
      marcarTodos(presentismo) {
        let self = this
        this.asistencia
          .marcarTodos(presentismo)
          .then(_ => self.updateAsistencia())
      },
      onError(err) {
        this.isError = err

        if (!h.isNullish(err)) {
          window.last_error = err
          window.console.error(err)

          this.$emit('error', err)
        }
      }
    },
  }
</script>

<style lang='scss' scoped>
  .asistencia-card {
    margin-bottom: 2em;
  }
</style>
