<template lang='pug'>
  v-menu(
    bottom left
  )
    template(v-slot:activator="{ on }")
      v-btn(
        v-on='on'
        fab icon small
      )
        v-icon {{icons.dots}}
    v-list
      v-list-item(
        @click='$emit("toggleAll", "presente")'
        key='marcar_presentes'
      )
        v-list-item-icon
          v-icon {{icons.presence}}
        v-list-item-content
          .
            {{i18n.asistencias.marcar_presentes}}
      v-list-item(
        @click='$emit("toggleAll", "ausente")'
        key='marcar_ausentes'
      )
        v-list-item-icon
          v-icon {{icons.absence}}
        v-list-item-content
          .
            {{i18n.asistencias.marcar_ausentes}}
</template>

<script>
  import { h, i18n, icons } from '@/../js/util'
  import { Asistencia } from '@/../models/asistencia.model'

  export default {
    props: {
    },
    data: () => {
      return {
        i18n,
        icons,
      }
    },
    computed: {
    },
    methods: {
    },
  }
</script>

<style lang='scss' scoped>
</style>
