<template lang='pug'>
  .asistencia-chips
    v-chip(
      v-if='isError && errorsCount > 0'
      outlined pill
      :color='color'
    )
      v-icon {{icons.alert}}
      .
        {{errorsCount}} error/es
    v-chip(
      v-if='isError && errorsCount == 0'
      outlined pill
      :color='color'
    )
      v-icon {{icons.alert}}
      .
        Sin conexión
    v-chip(
      v-if='isIncompleto'
      outlined pill
      :color='color'
    )
      v-icon {{icons.info}}
      .
        {{incompletosCount}} incompletos
</template>

<script>
  import { h, i18n, icons } from '@/../js/util'

  export default {
    props: {
      errorsCount: { type: Number, default: 0 },
      incompletosCount: { type: Number, default: 0 },
      isError: { type: Boolean, default: false },
      isIncompleto: { type: Boolean, default: false },
      isOpen: { type: Boolean, default: false },
    },
    data: () => {
      return {
        i18n,
        icons,
      }
    },
    computed: {
      color() {
        return this.isOpen ? "white" : "dark"
      }
    },
    methods: {
    },
  }
</script>

<style lang='scss' scoped>
  .asistencia-chips {
    margin-right: 0.5em;

    .v-chip {
      .v-icon {
        margin-right: 0.3em;
      }
    }
  }
</style>
