<template lang='pug'>
  .adjuntos.dropzone.row( :class="{ 'mini': isMini }" )
    c-adjunto(
      v-for='(adjunto, idx) in todosAdjuntos'
      v-if='!isMini'
      :key='`adjunto#${adjunto.id}`'
      :adjunto='adjunto'
      :adjuntable-name='adjuntableName'
      :index='idx'
      :habilitar-eliminacion='habilitarEliminacion'
      @deleted='deletedAdjunto'
    )
    .col-md-12.upload(v-if='!isMini && habilitarCreacion')
      .mini-box.file-link-box
        .box-icon.bg-success
          a(href='#')
            c-fa4(
              v-if='!uploading'
              icon='cloud-upload'
            )
            c-fa4(
              v-if='uploading'
              icon='circle-o-notch'
              spin=true
            )
        input(
          :data-reference='hexRand32'
          multiple='multiple'
          name="adjunto[file][]"
          type='hidden'
        )
        input(
          :data-reference='hexRand32'
          @presign:start='uploading = true'
          @upload:start='uploading = true'
          @upload:success='uploadSuccess'
          @upload:failure='uploadFailure'
          data-as='file'
          data-direct='true'
          data-presigned='true'
          data-url='/attachments/cache/presign'
          direct='true'
          id="adjunto_file"
          include_hidden="false"
          multiple='multiple'
          name="adjunto[file][]"
          presigned='true'
          ref='fileInput'
          type='file'
        )
    .col-md-12.upload(v-if='isMini')
      .mini-box
        template(v-if='habilitarCreacion')
          .box-icon(
            @click='popupBrowser'
            @dragover='onDragover'
            @dragenter='onDragover'
            @dragleave='onDragleave'
            @dragend='onDragleave'
            @drop='onDrop'
          )
            a(href='#')
              c-fa4(
                v-if='!uploading'
                icon='cloud-upload'
              )
              c-fa4(
                v-if='uploading'
                icon='circle-o-notch'
                spin=true
              )
          input(
            :data-reference='hexRand32'
            multiple='multiple'
            name="adjunto[file][]"
            type='hidden'
          )
          input(
            :data-reference='hexRand32'
            @dragover='onDragover'
            @dragenter='onDragover'
            @dragleave='onDragleave'
            @dragend='onDragleave'
            @drop='onDrop'
            @presign:start='uploading = true'
            @upload:start='uploading = true'
            @upload:success='uploadSuccess'
            data-as='file'
            data-direct='true'
            data-presigned='true'
            data-url='/attachments/cache/presign'
            direct='true'
            id="adjunto_file"
            include_hidden="false"
            multiple='multiple'
            name="adjunto[file][]"
            presigned='true'
            ref='fileInput'
            type='file'
            accept='image/jpeg,image/png,image/gif,application/pdf'
          )
        v-dialog(
          v-model='muestraModal'
          persistent
          max-width='960'
        )
          template(v-slot:activator='{ on }')
            a(
              v-on='on'
              href='#'
              @click='popupAdjuntos'
            ).
              {{i18n.adjuntos.label(todosAdjuntos.length)}}
          v-card.modal
            .modal-header
              button.btn.btn-default.pull-right(
                @click='muestraModal = false'
              )
                i.text-danger.fa.fa-times
              h4.
                Adjuntos
            v-card-text.modal-body
              v-container
                c-adjunto(
                  v-for='(adjunto, idx) in todosAdjuntos'
                  :key='`adjunto#${adjunto.id}`'
                  :adjunto='adjunto'
                  :adjuntable-name='adjuntableName'
                  :index='idx'
                  :habilitar-eliminacion='habilitarEliminacion'
                  @deleted='deletedAdjunto'
                )
            v-card-actions.modal-footer
              v-spacer
              button.btn.btn-success(
                @click='muestraModal = false'
              ).
                Cerrar
</template>

<script>
  import { h, i18n } from '@/../js/util'

  export default {
    props: {
      adjuntableType: {
        type: String,
        required: true,
      },
      adjuntableId: {
        type: String,
        required: false,
      },
      adjuntableName: {
        type: String,
        required: true,
      },
      ueId: {
        type: String,
        required: false,
      },
      adjuntos: {
        type: String,
        required: true,
      },
      extensiones: {
        default: '',
        type: String,
      },
      kind: {
        type: String,
      },
      habilitarCreacion: {
        default: false,
        type: Boolean,
      },
      habilitarEliminacion: {
        default: false,
        type: Boolean,
      },
    },
    data: () => {
      return {
        extensionesValidas: [],
        i18n,
        muestraModal: false,
        todosAdjuntos: [],
        uploading: false,
      }
    },
    mounted() {
      this.todosAdjuntos = JSON.parse(this.adjuntos);
      this.extensionesValidas = this.extensiones.split(',');
    },
    computed: {
      hexRand32() {
        return this.randomHexString(32)
      },
      isMini() {
        return this.kind == 'mini';
      }
    },
    methods: {
      randomHexString(len) {
        let maxlen = 8
        let min = Math.pow(16, Math.min(len, maxlen) - 1)
        let max = Math.pow(16, Math.min(len, maxlen)) - 1
        let n   = Math.floor(Math.random() * (max - min + 1)) + min
        let r   = n.toString(16)
        while (r.length < len) {
          r = r + this.randomHexString( len - maxlen );
        }
        return r;
      },
      async uploadSuccess(evt) {
        if (!h.isNullish(this.refileValue())) {
          if (this.isValid()) {
            const response = await fetch(
              '/adjuntos',
              {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                },
                method: 'post',
                body: JSON.stringify(this.postBody(true)),
              }
            );

            if(response.ok) {
              const json = await response.json();
              this.todosAdjuntos = this.todosAdjuntos.concat(json);
            } else {
              this.showError('No se pudo subir el archivo adjunto.');
            }
          } else {
            this.showError('Sólo se admiten archivos con estas extensiones: ' + this.extensionesValidas.join(', '));
          }
        }

        this.clear();
      },
      clear() {
        this.uploading = false;
        this.$refs.fileInput.value = '';
      },
      isValid() {
        return h.isBlank(this.extensionesValidas)
          || this.uploadExtensions().every((ext) => this.extensionesValidas.includes(ext));
      },
      uploadFailure() {
        this.clear();
        this.showError('No se pudo subir el o los archivos, posiblemente debido a problemas de conexión. Por favor reintente nuevamente.');
      },
      showError(message) {
        bootbox.alert(message);
      },
      uploadFileNames() {
        return [].map.apply(this.$refs.fileInput.files, [(file) => file.name]);
      },
      uploadExtensions() {
        return this.uploadFileNames().map((name) => {
          let parts = name.split('.');

          return parts[parts.length - 1];
        });
      },
      refileValue() {
        return this.hiddenInput().getAttribute('value')
      },
      postBody(includeFile) {
        return {
          adjunto: {
            adjuntable_type: this.adjuntableType,
            adjuntable_id: this.adjuntableId,
            ...(includeFile && { file: this.refileValue() }),
          },
          ue_id: this.ueId,
          ...this.csrf()
        }
      },
      csrf() {
        let out = {}
        let key = this.getMeta('csrf-param')
        let value = this.getMeta('csrf-token')

        out[key] = value

        return out
      },
      hiddenInput() {
        return this.$el.querySelector('input[name="adjunto[file][]"]')
      },
      getMeta(name) {
        let selector = `meta[name=${name}]`
        let elem = document.querySelector(selector)

        return elem.getAttribute('content')
      },
      popupBrowser(evt) {
        // TODO: try to avoid manual triggering of events on raw elements
        evt.preventDefault();
        evt.stopPropagation();
        this.$refs.fileInput.click();
      },
      popupAdjuntos(evt) {
        evt.preventDefault();
        evt.stopPropagation();
      },
      onDragover(evt) {
        this.$el.classList.add('dragging');
      },
      onDragleave(evt) {
        this.$el.classList.remove('dragging');
      },
      onDrop(evt) {
        this.$el.classList.remove('dragging');
      },
      async deletedAdjunto(adjunto) {
        let response = await fetch(
          `/adjuntos/${adjunto.id}`,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            method: 'delete',
            body: JSON.stringify(this.postBody(false))
          }
        )

        if (response.status === 204) {
          this.todosAdjuntos = this.todosAdjuntos.filter(
            (hs) => hs.id !== adjunto.id
          )
        } else {
          this.showError('No se pudo eliminar el archivo adjunto.');
        }
      },
    },
  }
</script>

<style lang='scss' scoped>
  .dropzone.mini {
    .mini-box {
      min-height: unset;
      padding: 5px;

      .upload {
        input[type=file] {
          min-width: 0;
          min-height: 0;
        }
      }

      input[type=file] {
        min-width: 1.5em;
        min-height: 0.6em;
        width: 1.5em;
        height: 0.6em;
      }

      .box-icon {
        font-size: 20px;
        height: 2.5em;
        line-height: 2.5em;
        transition: transform 0.2s ease-in, opacity 0.2s ease-in;
        width: 6em;
      }
    }

    &.dragging {
      .box-icon {
        transform: scale(1.3);
        opacity: 0.6;
      }
    }
  }

  .upload {
    .file-link-box {
      background-color: #fafafa;
      border: 2px dashed #ddd;
    }

    .box-icon {
      float: unset;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .modal {
    color: #767676;
    text-align: left;
  }
</style>
