<template lang='pug'>
  .col-lg-4.col-md-6
    input(
      :name="adjuntableName + '[adjunto_ids][]'"
      type='hidden'
      :value='adjunto.id'
    )
    .mini-box.file-link-box
      .box-icon.bg-info
        a(
          :href='adjunto.url'
          target='_blank'
        )
          c-fa4(
            :icon='adjunto.fa'
          )
      .box-info
        a(
          :href='adjunto.url'
          :title='adjunto.filename'
          target='_blank'
        )
          c-fa4(
            icon='download'
          )
          .
            &nbsp;{{adjunto.filename}}
        br/
        small.
          {{adjunto.filesize}}
        template(v-if='habilitarEliminacion')
          br/
          button.btn.btn-danger.btn-xs(
            @click='deleteMe'
            type='button'
            style='margin-top: 0.6em;'
          )
            c-fa4(icon='trash-o')
</template>

<script>
  export default {
    props: {
      adjunto: { type: Object, required: true },
      adjuntableName: {
        type: String,
        required: true,
      },
      habilitarEliminacion: {
        type: Boolean,
        default: false
      },
    },
    data: () => {
      return {
      }
    },
    methods: {
      deleteMe() {
        bootbox.confirm({
          title: 'Eliminación de Adjunto',
          message: 'Confirme que desea eliminar el archivo adjunto. Esta operación NO se puede deshacer.',
          buttons: {
            confirm: {
              label: 'Eliminar',
              className: 'btn-danger',
            },
            cancel: {
              label: 'Volver',
              className: 'btn-default',
            },
          },
          callback: (result) => {
            if (!result) { return }
            this.$emit('deleted', this.adjunto)
          }
        })
      }
    }
  }
</script>

<style lang='scss' scoped>
</style>
