export default {
  vuetify_example: {
    light: {
      primary: '#1976D2',
      secondary: '#424242',
      accent: '#82B1FF',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107'
    }
  },
  comenius: {
    light: {
      // secondary: '#7f6ec7',
      // accent: '#f3c536',
      primary: '#365e8f',
      secondary: '#999',
      accent: '#7f6ec7',
      error: '#f95426',
      info: '#27abed',
      success: '#94b758',
      warning: '#fc9403'
    }
  }
}

/* https://color.adobe.com/tsamba-na-kulay-color-theme-1100326 */
// #84E889;
// #91CDFF;
// #FFEB9D;
// #E89384;
// #BD87FF;
