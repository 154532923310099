<template lang='pug'>
  c-container(:loading='loading')
    template(#title)
      //- v-icon {{icons.calendar}}
      //- datetime(v-model='fecha')
      v-dialog(
        v-model='modal'
        :return-value.sync='fecha'
        ref='dialog'
        max-width='35em'
      )
        template(v-slot:activator='{ on }')
          v-text-field(
            v-model='fecha'
            v-on='on'
            :prependInnerIcon='icons.calendar'
            readonly
            hideDetails=true
            solo-inverted=true
          )
        v-date-picker(
          v-model='fecha'
          :max='tomorrow'
          full-width scrollable
          locale='es-AR'
        )
          .flex-grow-1
          v-btn(
            text color='secondary'
            fixed large
            @click='modal = false'
          ).
            {{i18n.datepicker.cancel}}
          v-btn(
            color='success'
            large
            @click='$refs.dialog.save(fecha)'
          ).
            {{i18n.datepicker.ok}}

    asistencias-list(
      :unidad-educativa-id='unidadEducativaId'
      :fecha='fecha'
      @load-start='loading = true'
      @load-stop='loading = false'
    )
</template>

<script>
  import { i18n, icons } from '@/../js/util'
  import { DateTime, Duration } from 'luxon'

  export default {
    props: {
      unidadEducativaId: String,
      url: String
    },
    data: () => {
      return {
        fecha: DateTime.local().toISODate(),
        i18n,
        icons,
        loading: false,
        modal: false,
      }
    },
    computed: {
      tomorrow() {
        let day = Duration.fromObject({ days: 1 });
        let now = DateTime.local()

        return now.plus(day).toISODate();
      },
      reportModal() {
        return JSON.parse(this.reportModalJson)
      }
    },
    methods: {
      askSendReport() {
        let modal = this.reportModal
        let data = modal.options.data

        bootbox.confirm({
          title: data.confirm_title,
          message: data.confirm_message,
          buttons: {
            confirm: {
              label: data.confirm_accept,
              className: data.confirm_accept_class,
            },
            cancel: {
              label: data.cancel_accept,
              className: data.cancel_accept_class,
            },
          },
          callback: (result) => {
            if (!result) { return }

            let url = modal.url
            let method = modal.options.method.toUpperCase()
            let csrf_token = document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute('content')

            // FIXME: CSRF token
            // let body = new FormData()
            // // body.append('authenticity_token', csrf_token) // crash

            [result, modal, data]
            let out = fetch(
              url, {
                // body: body,
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                method: method,
                cache: 'no-cache',
                redirect: 'manual',
                credentials: 'same-origin',
                withCredentials: true
              }
            )

            return out
          }
        })
      }
    }
  }
</script>

<style lang='scss'>
  .v-date-picker-table .v-btn {
    font-size: 15px;
    margin: 0.6em 0.4em;
  }

  .v-picker__body {
    height: 40vh;
  }
</style>
