<template lang='pug'>
  i.fa(
    :class='faClass'
  )
</template>

<script>
  export default {
    props: {
      icon: {
        type: String,
        required: true
      },
      spin: Boolean
    },
    computed: {
      faClass() {
        let classes = []
        classes.push(`fa-${this.icon}`)

        if (this.spin) {
          classes.push('fa-spin')
        }

        return classes;
      }
    },
  }
</script>
