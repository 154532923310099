import Vue from 'vue'

import TurbolinksAdapter from 'vue-turbolinks'
Vue.use(TurbolinksAdapter)

import vuetify from './vuetify'
import './vue-datetime'

import '../shared'
import '../features'

// necesario para darle acceso a Vue al JS de Sprockets
window.vue_class = Vue

// init
document.addEventListener('turbolinks:load', () => {
  if (window.debug == 'js') {
    window.console.debug('  ** vue_init: holdReady')
  }
  // necesario para evitar romper los behaviors de jQuery
  jQuery.holdReady(true)

  const app = new Vue({
    vuetify,
    el: '[data-behavior="vue"]',
    mounted() {
      while (jQuery.readyWait > 0) {
        if (window.debug == 'js') {
          window.console.debug(`releasing jQuery's readyWait: ${jQuery.readyWait}`)
        }
        jQuery.holdReady(false)
      }
    }
  })
})
